import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { SvgIconComponent } from '../../../../../../../../shared/components/svg-icon/svg-icon.component';
import { DialogRef } from '@angular/cdk/dialog';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sfa-feedback-dialog',
  standalone: true,
  imports: [
    MatFormField,
    SvgIconComponent,
    ReactiveFormsModule,
    MatInput,
    NgIf,
    MatHint,
  ],
  templateUrl: './feedback-dialog.component.html',
  styleUrl: './feedback-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDialogComponent {
  dialogRef = inject(DialogRef);
  formBuilder = inject(FormBuilder);
  http = inject(HttpClient);
  toastr = inject(ToastrService);

  form: FormGroup = this.formBuilder.group({
    message: ['', Validators.required],
    contactInformation: [''],
  });

  onSubmit() {
    if (this.form.invalid) return;

    const formData = this.form.value;
    const payload = {
      message: formData.message,
      contactInfo: formData.contactInformation,
    };

    // Make an HTTP POST request to your Firebase Function
    this.http
      .post(`${environment.domains.functionStripe}/feedback`, payload)
      .subscribe({
        next: () => {
          this.toastr.success('Nachricht gesendet!');
          this.dialogRef.close();
        },
        error: () => {
          this.toastr.error(
            'Fehler beim Senden, bitte versuche es später erneut',
          );
        },
      });
  }
}
