<sfu-svg-icon
  class="close-icon"
  src="close"
  svgClass="icon-button"
  (click)="dialogRef.close()"
></sfu-svg-icon>

<h3>Feedback & Support</h3>
<p>
  Du willst uns Feedback geben oder benötigst Hilfe? Kontaktiere uns einfach
  hier. Wir antworten in der Regel innerhalb weniger Stunden.
</p>
<form
  tabindex="0"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  (keydown.enter)="onSubmit()"
>
  <div class="form-group">
    <label for="message">Nachricht</label>
    <mat-form-field appearance="outline">
      <textarea
        matInput
        required
        formControlName="message"
        id="message"
        name="message"
        type="text"
        class="form-control"
        maxlength="2500"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="contactInformation">Wie können wir dich erreichen?</label>
    <mat-form-field appearance="outline" class="without-subscript">
      <input
        matInput
        type="text"
        class="form-control"
        id="contactInformation"
        name="contactInformation"
        formControlName="contactInformation"
        maxlength="200"
      />
    </mat-form-field>
  </div>

  <div class="actions">
    <button (click)="dialogRef.close()">Abbrechen</button>
    <button class="button-primary" type="submit">Senden</button>
  </div>
</form>
